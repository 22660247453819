<template>
  <div class="ingredient-content">
    <div class="ingredient-content__title">
      Kilos diarios requeridos (TCO)
    </div>
    <div class="ingredient-content__table">
      <div class="ingredient-content__row">
        <div class="ingredient-content__column">
          Alimento balanceado
        </div>
        <div
          class="ingredient-content__column"
          v-if="dailyWeightRequirement">
          {{ dailyWeightRequirement }} kg
        </div>
        <div
          class="ingredient-content__column"
          v-if="!dailyWeightRequirement">
          -
        </div>
      </div>
    </div>
    <div class="nutritional-information__disclaimer">
      *Valores excluyen sales
    </div>
  </div>
</template>
<script>

export default {
  computed: {
    dailyWeightRequirement() {
      return this.$store.state.recipe.dailyWeightRequirement;
    },
  },
};
</script>
