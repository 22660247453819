import Vue from 'vue';
import Vuex from 'vuex';
import moment from 'moment';
import VuexPersist from 'vuex-persist';

import api from './api';
import helpers from '../helpers';

import order from './modules/order';
import recipe from './modules/recipe';
import formulator from './modules/formulator';

const IVA_PERCENTAGE = 0.19;
const windowLoggedIn = window.siteVariables.loggedIn;
const enablePersist = window.siteVariables.enablePersist;
const enabledMultiparticleFormat = window.siteVariables.enabledMultiparticleFormat;

const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: window.sessionStorage,
  reducer: (state) => ({
    recipe: state.recipe,
    formulator: state.formulator,
    loggedIn: state.loggedIn,
  }),
  saveState: (key, state, storage) => {
    if (state.loggedIn) {
      storage.setItem(key, JSON.stringify(state));
    } else {
      storage.removeItem(key);
    }
  },
  restoreState: (key, storage) => {
    let state = storage.getItem(key);
    if (!state) return {};

    state = typeof state === 'string' ? JSON.parse(state) : state;
    const { loggedIn: _storageLoggedIn, ...stateWithoutLoggedIn } = state;

    if (!windowLoggedIn) {
      storage.removeItem(key);

      return {};
    }

    return stateWithoutLoggedIn;
  },
});
const vuexPlugins = enablePersist ? [vuexLocalStorage.plugin] : [];

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    ingredients: {},
    products: [],
    item: {
      form: 'powder',
      packaging: 'bulk',
    },
    showOverlay: false,
    alisurCoords: { lat: -40.484364, lng: -73.029934 },
    via: null,
    nextPath: '/',
    baseSackServicePrice: 0,
    firstRecipeLoaded: false,
    highPrecisionGoals: {
      expectedMilk: 0,
      producedMilkEm: 0,
      producedMilkPm: 0,
      expectedDryMatter: 0,
      givenDryMatter: 0,
      carbonEmission: 0,
    },
    showGoals: false,
    commission: null,
    onlinePayCommission: 0.015,
    depositPayCommission: 0.01,
    loggedIn: windowLoggedIn,
  },
  mutations: {
    setIngredients: (state, payload) => {
      state.ingredients = payload;
    },
    updateOrder: (state, payload) => {
      state.order = payload;
    },
    updateShowOverlay: (state, payload) => {
      state.showOverlay = payload;
    },
    updateVia: (state, payload) => {
      state.via = payload;
    },
    updateSackServiceBasePrice: (state, payload) => {
      state.baseSackServicePrice = parseFloat(payload);
    },
    updateFirstRecipeLoaded: (state, payload) => {
      state.firstRecipeLoaded = payload;
    },
    updateHighPrecisionGoals: (state, payload) => {
      state.highPrecisionGoals = payload;
    },
    updateShowGoals: (state, payload) => {
      state.showGoals = payload;
    },
    updateCommission: (state, payload) => {
      state.commission = Number(payload);
    },
    updateLoggedIn: (state, payload) => {
      state.loggedIn = payload;
    },
  },
  actions: {
    setIngredients: ({ commit, dispatch }, ingredients) => {
      const ingredientsByName = ingredients.reduce((ingredientsHash, ingredient) => {
        const range = ingredient.max_percentage - ingredient.min_percentage + 1;

        ingredientsHash[ingredient.name] = {
          ...ingredient,
          min: ingredient.min_percentage,
          max: ingredient.max_percentage,
          value: ingredient.default_percentage,
          rangeValue: [ingredient.min_percentage, ingredient.max_percentage],
          interval: helpers.stepSizeForRange(range),
        };

        return ingredientsHash;
      }, {});
      commit('setIngredients', ingredientsByName);
      dispatch('setIngredientRanges');
      dispatch('setIngredientFormulatorValues');
    },
    createOrder: (context) => {
      api.createOrder(context.getters.order)
        .then((response) => {
          const link = response.transbankData?.url || response.order;
          window.location.href = link;
        });
    },
    setDateAvailabilityAndFees({ commit }, dateAvailabilityAndFees) {
      commit('updateDisabledDates', dateAvailabilityAndFees.disabled_dates);
      commit('updateDatesWithFees', dateAvailabilityAndFees.dates_with_fees);
      commit('updateLastDate', dateAvailabilityAndFees.last_date);
    },
    updateFreightService: context => {
      const query = (new URL(decodeURIComponent(window.location.href))).search.replace('?', '').split('=');
      const lat = context.state.order.address.marker.position.lat;
      const lng = context.state.order.address.marker.position.lng;
      const packaging = context.state.recipe.finishing.packaging.value;
      const totalSackWeight = context.state.recipe.finishing.totalSackWeight;
      const productId = query[0] === 'product' ? query[1] : null;

      api.getOrderFreightValue({ lat, lng, productId, packaging, totalSackWeight })
        .then((value) => {
          context.commit('updateContactUserServiceFreight', value);
        });
    },
    setProductDetails({ commit, getters }, product) {
      commit('updateForm', getters.allTranslatedForms.find(e => e.value === product.form));
      commit('updatePackaging', getters.productPackagingList.find(e => e.value === product.packaging));
      commit('updateProductInformation', product);
      commit('updateProductIngredient', product);
      commit('updateFormulaName', product.name);
    },
    updateViewMode: (context, payload) => {
      context.commit('updateViewMode', payload);
    },
    updateFormulaName: (context, payload) => {
      context.commit('updateFormulaName', payload);
    },
    updateWayToPay: (context, payload) => {
      context.commit('updateWayToPay', payload);
    },
    updateForm: (context, payload) => {
      context.commit('updateForm', payload);
    },
    updatePackaging: (context, payload) => {
      context.commit('updatePackaging', payload);
    },
    updateShowOverlay: (context, payload) => {
      context.commit('updateShowOverlay', payload);
    },
    setUpdated: (context, payload) => {
      context.commit('setUpdated', payload);
    },
    updateVia: (context, payload) => {
      context.commit('updateVia', payload);
    },
    updateSackServiceBasePrice: (context, payload) => {
      context.commit('updateSackServiceBasePrice', payload);
    },
    updateHighPrecisionGoals: (context, payload) => {
      context.commit('updateHighPrecisionGoals', payload);
    },
    updateShowGoals: (context, payload) => {
      context.commit('updateShowGoals', payload);
    },
  },
  getters: {
    params: (state) => {
      if (state.recipe.type !== 'product') {
        const queryObject = {};
        const keys = Object.keys(state.recipe.ingredientsProportion);
        for (let i = 0; i < keys.length; i++) {
          queryObject[state.ingredients[keys[i]].id] = state.recipe.ingredientsProportion[keys[i]];
        }

        return queryObject;
      }

      return null;
    },
    googleMapUrl: state => {
      if (state.order.address.reference === null) {
        return '/';
      }
      const ref = state.order.address.reference.split(',')[0];
      const marker = state.order.address.marker;

      return `https://www.google.cl/maps/place/${ref}/@${marker.position.lat},${marker.position.lng},17z`;
    },
    order: (state, getters) => {
      const productId = state.recipe.details === null ? null : state.recipe.details.id;
      const item = {
        'type': state.recipe.type,
        'finishing': state.recipe.finishing,
        'product_id': productId,
        'ingredients': getters.ingredientsWithProportionsForOrder,
        'nutritional_info': state.recipe.nutritionalInfo,
      };
      const orderData = {
        'item': item,
        'user': state.order.user,
        'address': state.order.address,
        'date': state.order.desiredDate,
        'way_to_pay': state.order.wayToPay,
        'via': state.via,
        'formula_name': state.order.formulaName,
        'has_sack_service': getters.sackServiceValue > 0,
        'dairy_info': state.formulator.dairyInfo,
      };

      return orderData;
    },
    subTotal: (state) => (state.order.isCoagraWeb ? 0 : state.recipe.price * state.recipe.weight),
    serviceDateValue: (state) => (state.order.isCoagraWeb ? 0 : state.order.services.date),
    serviceFreightValue: (state) => (state.order.isCoagraWeb ? 0 : state.order.services.freight),
    sackServiceValue: (state) => (state.order.isCoagraWeb ? 0 : state.order.services.sackDischarge),
    netTotalWithoutSalesService: (_state, getters) => (
      getters.subTotal + getters.serviceFreightValue + getters.serviceDateValue + getters.sackServiceValue
    ),
    finalCommission(state) {
      if (state.order.wayToPay === 'online') {
        return state.onlinePayCommission + state.commission;
      } else if (state.order.wayToPay === 'deposit') {
        if (state.commission > state.depositPayCommission) {
          return state.commission - state.depositPayCommission;
        }

        return 0;
      }

      return state.commission;
    },
    salesService: (_state, getters) => (getters.netTotalWithoutSalesService * getters.finalCommission),
    netTotal: (_state, getters) => (getters.netTotalWithoutSalesService + getters.salesService),
    iva: (_state, getters) => (Math.round(getters.netTotal * IVA_PERCENTAGE)),
    totalPrice: (_state, getters) => (getters.netTotal + getters.iva),
    sackServiceBasePrice: state => (state.baseSackServicePrice),
    orderItemFinishingType: state => (state.recipe.finishing),
    datePrice: (state) => (_date) => {
      if (!_date) return undefined;

      let price = 0;
      Object.keys(state.order.datesWithFees).forEach(fee => {
        const dateRange = state.order.datesWithFees[fee];
        const start = dateRange.start_date;
        const end = dateRange.end_date;

        if (moment(_date).isSameOrAfter(start) && moment(_date).isSameOrBefore(end)) {
          price += Number(fee);
        }
      });

      return price;
    },
    dateArrayWithFees: state => {
      const dates = [];
      const disabledDates = state.order.disabledDates.map(date => new Date(date).toString());

      Object.keys(state.order.datesWithFees).forEach(fee => {
        const dateRange = state.order.datesWithFees[fee];
        const end = moment(dateRange.end_date);
        let start = moment(dateRange.start_date);

        while (start <= end) {
          const isDisableDate = disabledDates.includes(start.toDate().toString());
          if (!isDisableDate) {
            dates.push(start.toDate());
          }
          start = moment(start).add(1, 'days');
        }
      });

      return dates;
    },
    isValidOrder: state => {
      const form = state.order;
      const user = form.user;
      const validUserForm = !!user.name && !!user.surname && !!user.numberPrincipal &&
        !!user.bname && !!user.rut && !!user.bcategory && !!user.baddress;
      const validMap = !isNaN(parseInt(form.services.freight, 10)) && form.address.name && form.address.reference;
      const validDate = !!form.desiredDate;

      if (validUserForm && validMap && validDate && form.viewMode) {
        return true;
      }

      return false;
    },
    ingredientsArray(state) {
      return Object.values(state.ingredients);
    },
    generalIngredients(_state, getters) {
      return getters.ingredientsArray.filter(ingredient => ingredient.category === 'standard');
    },
    saltIngredients(_state, getters) {
      return getters.ingredientsArray.filter(ingredient => 
        ['salt_others', 'salt_alisur'].includes(ingredient.category)
      );
    },
    formulatorIngredients(_state, getters) {
      return getters.generalIngredients.concat(getters.saltIngredients);
    },
    ingredientsForSelect: (_state, getters) => (ingredientType) => {
      const ingredientsArray = [];
      getters.ingredientsArray.forEach(ingredient => {
        ingredientsArray.push({
          category: ingredient.category,
          value: ingredient.id,
          label: ingredient.name,
          defaultPercentage: ingredient.value,
          ms: ingredient.ms,
        });
      });

      return ingredientsArray.filter(ingredient => ingredient.category === ingredientType ||
         ingredientType.includes(ingredient.category));
    },
    ingredientsWithProportionsForOrder(state) {
      return Object.entries(state.recipe.ingredientsProportion).reduce((ingredients, [name, proportion]) => {
        if (state.ingredients[name]) {
          ingredients.push({ id: state.ingredients[name].id, value: proportion });
        }

        return ingredients;
      }, []);
    },
    extendedPackagingList() {
      return [
        { value: 'bulk', label: 'Granel' },
        { value: 'bagged25', weight: 2, label: 'Ensacado 25 kg (2 tons)', shortLabel: 'Ensacado 25 kg' },
        { value: 'bagged25', weight: 4, label: 'Ensacado 25 kg (4 tons)', shortLabel: 'Ensacado 25 kg' },
        { value: 'bagged25', weight: 6, label: 'Ensacado 25 kg (6 tons)', shortLabel: 'Ensacado 25 kg' },
        { value: 'bagged25', weight: 8, label: 'Ensacado 25 kg (8 tons)', shortLabel: 'Ensacado 25 kg' },
        { value: 'bagged25', weight: 10, label: 'Ensacado 25 kg (10 tons)', shortLabel: 'Ensacado 25 kg' },
        { value: 'bagged25', weight: 15, label: 'Ensacado 25 kg (15 tons)', shortLabel: 'Ensacado 25 kg' },
      ];
    },
    allTranslatedForms() {
      const forms = ['powder', 'multiparticle', 'pellet12', 'pellet8', 'pellet6', 'pellet3', 'whole', 'ground'];

      return helpers.translateList(forms);
    },
    pelletForms() {
      return ['pellet12', 'pellet8', 'pellet6', 'pellet3'];
    },
    translatedFormsForFormulatorSelect: (state, getters) => {
      const bulkOnlyForms = ['powder'];
      if (enabledMultiparticleFormat) bulkOnlyForms.push('multiparticle');

      if (state.recipe.finishing.packaging.value === 'bagged25') {
        return helpers.translateList(getters.pelletForms);
      }

      return helpers.translateList(bulkOnlyForms.concat(getters.pelletForms));
    },
    productPackagingList() {
      const packagings = ['bulk', 'bagged25'];

      return helpers.translateList(packagings);
    },
    queryIngredients: (state, getters) => (salts) => {
      const saltsFilter = ['salt_others', 'salt_alisur'];
      const query = getters.generalIngredients
        .concat(getters.saltIngredients)
        .filter(ingredient => !saltsFilter.includes(ingredient.category))
        .map(ingredient => ({ id: ingredient.id, value: ingredient.value }));

      salts.forEach(salt => {
        if (salt.id && salt.amount > 0.0) {
          query.push({ id: salt.id, value: salt.amount });
        }
      });

      return query;
    },
    initialized: state => state.recipe.type !== '',
  },
  modules: {
    order,
    recipe,
    formulator,
  },
  plugins: vuexPlugins,
});

export default store;
