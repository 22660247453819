<template>
  <formulator-slide-and-select
    name="Sal Mineral"
    unit="%"
    min="0"
    max="8"
    interval="0.1"
    v-model="salt"
    :info-modal="infoModal"
    :items="salts"
    :update-on-select="false"
  />
</template>
<script>
import FormulatorSlideAndSelect from './formulator-slide-and-select';

export default {
  components: {
    FormulatorSlideAndSelect,
  },
  data() {
    return {
      salt: this.value,
    };
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    ignoredSalts: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  computed: {
    infoModal() {
      if (this.$store.state.order.isCoagraWeb) {
        return null;
      }

      return 'salt-modal';
    },
    ignoredSaltIds() {
      return this.ignoredSalts.map(salt => salt.id);
    },
    salts() {
      return this.$store.getters.ingredientsForSelect(['salt_alisur',
        'salt_others']).filter(ingredient =>
        !this.ignoredSaltIds.includes(ingredient.value));
    },
  },
  watch: {
    salt(val) {
      this.$emit('input', val);
    },
  },
};
</script>
